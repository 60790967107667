<template>
  <base-link
    to="/location-selector"
    class="flex items-center gap-2 hover:underlined"
    @click="$gtm.push('topNav.onClickCountrySelector')"
  >
    <vf-location-flag />
    {{ languageCode }}
    <slot />
  </base-link>
</template>

<script lang="ts" setup>
const languageCode = useLanguageCode()
</script>
